import React, { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';

const RedirectPage = () => {
  useEffect(() => {
    // Specify the URL you want to redirect to
    const targetURL = "https://bouncy.ai/Gigibunny30";
    window.location.href = targetURL;
  }, []);

  return (
    <div>
      <Analytics/>
    </div>
  );
}

export default RedirectPage;
